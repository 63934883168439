import React from "react";
import Layout from "../components/shared/layout/layout";
import Seo from "../components/seo";
import ThankYou from "../components/thank-you/thank-you";

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <ThankYou/>
  </Layout>
)

export default ThankYouPage
