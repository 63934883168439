import React from "react";
import {Link} from "gatsby";
import "./thank-you.scss";
import { graphql, useStaticQuery} from "gatsby";

const ThankYou = () => {
  const data = useStaticQuery(graphql`
    query {
      introImg: file(relativePath: { eq: "home-page-first-section-img.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <div className="make-software container">
      <div className="make-software-left"
           data-sal="slide-right"

           data-sal-easing="ease-in-cubic"
           data-sal-duration="1000"
      >
        <h2>Thank you for reaching out! We will be in touch.</h2>
        <Link to="/" className="yellow-btn" >Explore Site</Link>
      </div>
      <div className="make-software-right">
        {/* <div className="grey-circle"></div> */}
        <img alt="" src={data.introImg.publicURL}/>
      </div>
    </div>
  )
};

export default ThankYou
